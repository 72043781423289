<!--  -->
<template>
  <div id="list-complete-demo" class="demo">
    <button v-on:click="shuffle">Shuffle</button>
    <button v-on:click="add">Add</button>
    <button v-on:click="remove">Remove</button>
    <transition-group name="list-complete" tag="p">
      <div id="ttt" class="ttt">
        <div
          :data-group="JSON.stringify(item.group)"
          v-for="item in items"
          v-bind:key="item"
          class="list-complete-item"
        >
          {{ item.id }}
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import _ from "lodash";
import Shuffle from "shufflejs";
export default {
  name: "shuffle",
  data() {
    //这里存放数据
    return {
      items: [
        { id: 1, group: [1] },
        { id: 2, group: [1] },
        { id: 3, group: [1] },
        { id: 4, group: [2] },
        { id: 5, group: [2] },
        { id: 6, group: [2] },
        { id: 7, group: [3] },
        { id: 8, group: [3] },
        { id: 9, group: [3] },
        { id: 10, group: [4] },
      ],
      nextNum: 10,
    };
  },
  methods: {
    randomIndex: function () {
      return Math.floor(Math.random() * this.items.length);
    },
    add: function () {
      this.Shuffle.sort()
      // this.Shuffle.remove([1])
      // return
      // this.items.splice(this.randomIndex(), 0, this.nextNum++);
      // this.Shuffle.splice()
    //   this.Shuffle.filter((e) => {
    //     // console.log(e)
    //     if ($(e).eq(0).attr("data-group") == JSON.stringify([1])) {
    //       this.Shuffle.remove([e]);
    //     }

    //     this.Shuffle.update();
    //     // return this.Shuffle.remove(e)
    //   });
    },
    remove: function () {
      this.Shuffle.filter();
    },
    shuffle: function () {
      this.items = _.shuffle(this.items);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.$nextTick(() => {
      this.Shuffle = new Shuffle($("#ttt"), {
        itemSelector: ".list-complete-item",
      });
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style scoped>
.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
  width: 400px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: chartreuse;
  margin: 10px;
}
.list-complete-enter, .list-complete-leave-to
           /* .list-complete-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
.ttt {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  transition: all 1s;
}
</style>